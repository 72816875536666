const getIdFromUrl = (): string | null => {
  return new URL(window.location.href).searchParams.get('id');
};

const isResume = (): boolean => {
  const returnValue = new URL(window.location.href).searchParams.get('resume');
  return returnValue === '1';
};

const getEnvVar = (name: string): string | undefined => {
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
  return typeof process.env[name] !== 'undefined'
    ? process.env[name]
    : /* eslint-disable-next-line  */
      // @ts-ignore
      (window as Window & typeof globalThis & { env: object | undefined }).env?.[name];
};

export { getIdFromUrl, getEnvVar, isResume };

import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';

const NotFound = () => {
  const { t } = useTranslation(['main']);

  return (
    <Container sx={{ height: '100%', display: 'flex' }} maxWidth="xl">
      <div>
        <p>{t('main:page.notFound.content')}</p>
      </div>
    </Container>
  );
};

export default NotFound;

import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import './style.scss';
import { getEnvVar, getIdFromUrl } from '../../utils';
import PaymenttoolsSDK from '../../../types/PaymenttoolsSDK';
import { PaymentProps } from '../../components/PageLayout/types';

declare const Paymenttools: PaymenttoolsSDK;

const Payment = ({ displayLogo, isSessionLoaded }: PaymentProps) => {
  const [dropInLoadingFailed, setDropInLoadingFailed] = useState<boolean>(false);
  const [ptSdkScriptLoaded, setPtSdkScriptLoaded] = useState<boolean>(false);
  const { t } = useTranslation(['error']);

  useEffect(() => {
    if (!isSessionLoaded) return;
    const src = getEnvVar('REACT_APP_SDK_LIBRARY_URL');
    if (src) {
      const ptSdkScript = document.createElement('script');
      ptSdkScript.src = src;
      ptSdkScript.addEventListener('load', () => {
        setPtSdkScriptLoaded(true);
      });
      document.head.prepend(ptSdkScript);
    }
  }, [isSessionLoaded]);

  useEffect(() => {
    if (ptSdkScriptLoaded) {
      const sessionId = getIdFromUrl();
      try {
        const dropIn = new Paymenttools.DropIn('sdk-container', {
          sessionId,
          language: i18next.language,
          displayLogo
        });

        i18next.on('languageChanged', (language) => {
          dropIn.changeLanguage(language);
        });
      } catch (error) {
        setDropInLoadingFailed(true);
      }
    }
  }, [ptSdkScriptLoaded, displayLogo]);

  return (
    <div id="sdk-container">
      {dropInLoadingFailed && <div>{t('error:dropInLoadingFailed')}</div>}
    </div>
  );
};

export default Payment;

import React from 'react';
import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { SessionDataPaypage } from '../PageLayout/types';

const Footer = ({ theme }: SessionDataPaypage) => {
  const { t } = useTranslation(['main']);

  return (
    <AppBar
      variant="elevation"
      position="relative"
      color="primary"
      component="footer"
      style={{ backgroundColor: theme.primaryColor, color: theme.primaryFontColor }}>
      <Toolbar variant="regular">
        <Container
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          disableGutters
          maxWidth="xl">
          <Typography>
            &copy; {new Date().getFullYear()} {t('main:footer.allRightsReserved')}
          </Typography>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import mainDe from './translations/de/main.json';
import mainEn from './translations/en/main.json';
import errorDe from './translations/de/error.json';
import errorEn from './translations/en/error.json';

export const defaultNS = 'main';
export const resources = {
  de: {
    main: mainDe,
    error: errorDe
  },
  en: {
    main: mainEn,
    error: errorEn
  }
} as const;

let lang = 'de';

if (sessionStorage.getItem('ptPreferredLanguage')) {
  lang = sessionStorage.getItem('ptPreferredLanguage') as string;
}

i18n
  .use(initReactI18next)
  .init({
    lng: lang,
    fallbackLng: ['de', 'en'],
    ns: ['main'],
    defaultNS,
    resources,
    returnNull: false
  })
  .then(() => {
    sessionStorage.setItem('ptPreferredLanguage', lang);
  })
  .catch(() => {
    // do nothing
  });

import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';

const PrivacyPolicy = () => {
  const { t } = useTranslation(['main']);
  return (
    <Container sx={{ height: '100%', display: 'flex' }} maxWidth="xl">
      <div>
        <h1>{t('main:pages.privacyPolicy')}</h1>
        <p>{t('main:pages.content1')}</p>
        <p>{t('main:pages.content2')}</p>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;

import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import theme from '../../utils/theme';
import PageLayout from '../PageLayout';

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (sessionStorage.getItem('ptPreferredLanguage')) {
      i18n
        .changeLanguage(sessionStorage.getItem('ptPreferredLanguage') as string)
        .then(() => {})
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <PageLayout />
    </ThemeProvider>
  );
};

export default App;
